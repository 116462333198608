* {
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
  }

  body {
    background-color: #303030;
  }

  /* width */
::-webkit-scrollbar {
  width: 10px;
  background: none;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #B0B4BA;
  border-radius: 7px; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}